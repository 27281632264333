<template>
    <Link :link="teaser.link || {}" class="product-details-link">
        <div class="home-teaser">
            <div class="teaser-text container">
                <p class="teaser-title">
                    <span class="teaser-text__title">{{ teaser.title }}</span>

                    <!-- Webshop only area -->
                    <template v-if="hasPrice">
                        <span class="teaser-title-separator">|</span>
                        <!--Price ignores price mode since not all three prices are provided for now-->
                        <Price
                            :minimumPrice="teaser.minimumPrice"
                            :minimumPerPersonPrice="teaser.minimumPerPersonPrice"
                            ignorePriceMode
                            hide-help
                            class="teaser-price"
                        >
                            <DiscountBadge
                                v-if="teaser.maximumDiscountPercentage"
                                :discount="teaser.maximumDiscountPercentage"
                                class="teaser-discount"
                            >
                            </DiscountBadge>
                        </Price>
                    </template>
                </p>
                <p class="teaser-subtitle teaser-text__subtitle">{{ teaser.text }}</p>
            </div>
        </div>
    </Link>
</template>

<script>
import Price from '@/components/Price';
import DiscountBadge from '@/components/DiscountBadge';

export default {
    name: 'HomeTeaser',
    /*data() {
            return {
                theme: process.env.VUE_APP_THEME
            };
        },*/
    props: {
        teaser: Object
    },
    components: {
        Price,
        DiscountBadge
    },
    computed: {
        hasPrice() {
            return (
                !this.$store.state.globalMeta.multivoucherShop &&
                (this.teaser.minimumPrice ||
                    this.teaser.minimumPerPersonPrice ||
                    this.teaser.minimumPerPersonPerNightPrice)
            );
        }
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use 'sass:map';
$bottom: 20px;

.teaser-99er {
    top: 143px;
    position: relative;
    left: 282px;
    text-align: left;

    .teaser-red {
        display: inline-block;
        padding: 14px 44px 8px 40px;
        background-color: hsla(351, 83%, 48%, 0.9);
        font-size: 96px;
        font-weight: 600;
        line-height: 1;

        .ers {
            font-size: 64px;
            font-weight: 400;
            padding-left: 8px;
        }
    }

    span {
        color: hsl(0, 0%, 100%);
        vertical-align: text-top;
        line-height: 1;
    }

    .teaser-arrow {
        width: 0;
        height: 0;
        border-left: 41px solid transparent;
        border-right: 0 solid transparent;
        border-top: 41px solid hsla(347, 100%, 33%, 0.9);
    }
}

.product-details-link {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    align-items: flex-end;
    text-decoration: none;
    bottom: 0;

    .home-teaser {
        background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
        padding-top: 40px;
        padding-bottom: 20px;
        width: 100%;
        z-index: map.get(variables.$z-indices, 'giftbox-banner') + 1;

        .teaser-text,
        .teaser-text .teaser-subtitle {
            color: variables.$white;
        }

        .teaser-text {
            z-index: 3;

            .teaser-title {
                font-size: 28px;
                line-height: 35px;
                font-weight: 700;
                margin: 6px 0;
            }

            .teaser-title-separator {
                margin: 0 20px;
            }

            .teaser-subtitle {
                font-size: 20px;
                line-height: 25px;
                margin-bottom: 3px;
            }

            .teaser-discount {
                margin-left: 5px;
            }
        }
    }
}
</style>
