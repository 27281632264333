export default pageKey => ({
    computed: {
        extraScriptCode() {
            return this[pageKey].extraScriptcode;
        }
    },
    beforeCreate() {
        console.assert(!!pageKey, 'Page data vuex key should be provided');
    },
    mounted() {
        if (this.extraScriptCode) {
            try {
                window.eval(this.extraScriptCode);
            }
            catch (e) {
                this.$isDev && console.log('Cannot evaluate page external script code');
            }
        }
    }
});
