<template>
    <span class="price" v-show="isClientSide">
        <span class="price-values d-inline-flex">
            <!--extra span wrapper to separate price from slot (usually discount badge). Makes possible to align from and per person as baseline-->
            <span v-if="showPrefix" class="prefix" :class="{ small: smallPrefix }">{{ prefix }}</span>

            <!--Sometimes the price can be undefined ignore price mode set to true-->
            <span
                v-if="minPrice"
                class="price-value"
                :class="{ [priceModes.package.cssClass]: true, 'd-inline-block': ignorePriceMode }"
            >
                {{ minPrice }}
            </span>
            <span v-if="minPersonPrice" class="price-value" :class="perPersonCSS">
                {{ minPersonPrice }}
            </span>
            <span v-if="minNightPrice" class="price-value" :class="perNightCSS">
                {{ minNightPrice }}
            </span>
        </span>

        <slot></slot>

        <span v-if="!hideHelp" class="help-text">
            <span v-if="minNightPrice" :class="perNightCSS">{{ $t('Price.perPersonPerNight') }}</span>
            <span v-if="minPersonPrice" :class="perPersonCSS">
                {{ $t('Price.perPerson') }}
            </span>
        </span>
    </span>
</template>

<script>
import formatPriceFilter from '@/filters/formatPrice';
import priceModes from '@/models/priceModes';

export default {
    name: 'Price',
    data() {
        return {
            priceModes,
            isClientSide: process.client //needed to toggle the price itself only on the client side
        };
    },
    props: {
        minimumPrice: [Number, String],
        minimumPerPersonPrice: [Number, String],
        minimumPerNightPrice: [Number, String],
        prefix: {
            type: String,
            default() {
                return this.$t('Price.prefixDefault');
            }
        },
        hideHelp: Boolean,
        showPrefix: {
            type: Boolean,
            default: true
        },
        smallPrefix: Boolean,
        ignorePriceMode: Boolean,
        hideCents: Boolean
    },
    computed: {
        minPrice() {
            if (typeof this.minimumPrice === 'number') {
                return formatPriceFilter(this.minimumPrice, this.hideCents);
            }
            return this.minimumPrice;
        },
        minPersonPrice() {
            if (typeof this.minimumPerPersonPrice === 'number') {
                return formatPriceFilter(this.minimumPerPersonPrice, this.hideCents);
            }
            return this.minimumPerPersonPrice;
        },
        minNightPrice() {
            if (typeof this.minimumPerNightPrice === 'number') {
                return formatPriceFilter(this.minimumPerNightPrice, this.hideCents);
            }
            return this.minimumPerNightPrice;
        },
        perPersonCSS() {
            return {
                [this.priceModes.perPerson.cssClass]: true,
                'd-inline-block': this.ignorePriceMode
            };
        },
        perNightCSS() {
            return {
                [this.priceModes.perNight.cssClass]: true,
                'd-inline-block': this.ignorePriceMode
            };
        }
    },
    methods: {
        getHelpHTML() {
            let html = '';
            if (this.minNightPrice) {
                html +=
                    '<span class=' +
                    this.priceModes.perNight.cssClass +
                    '>' +
                    this.$t('Price.perPersonPerNight') +
                    '</span>';
            }
            if (this.minPersonPrice) {
                html +=
                    '<span class=' + this.priceModes.perPerson.cssClass + '>' + this.$t('Price.perPerson') + '</span>';
            }
            return html;
        }
    }
};
</script>

<style scoped lang="scss">
.price {
    position: relative;

    &.hide-on-server {
        display: none;
    }

    .price-value,
    .prefix {
        align-self: baseline;
        display: inline-block;
    }

    //.d-inline-block stands for ignore price flag
    //Only one forced to be shown price should be visible, following siblings are hidden
    .price-value.d-inline-block ~ .price-value {
        display: none !important;
    }

    .prefix {
        line-height: 1;
        margin-right: 5px; /*margin should be present (product-details select to test)*/
        font-weight: inherit;

        &.small {
            font-size: 50%;
            font-weight: inherit;
        }
    }

    .help-text {
        font-size: 0.5em;
        font-weight: normal;
        letter-spacing: 1px;
        bottom: -1em;
        left: 0; //IE
        position: absolute;
    }
}
</style>
