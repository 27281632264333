<!--
    Example https://github.com/lukaszflorczak/vue-agile/blob/master/src/Agile.vue
-->

<template>
    <div v-skeleton="$slots.default" class="carousel" @mouseleave="handleMouseLeave" ref="carousel">
        <div class="carousel-track" ref="track">
            <!--
                v-once to prevent slot items from unnecessary rerendering.
                Previously carousel's siblings were causing slot items updates, thus loosing dynamically added classes
            -->
            <slot />
        </div>
        <ul class="slide-control" ref="dots" v-if="slidesCount > 1">
            <li
                v-for="(slide, index) in slides"
                :key="'slideBullet' + index"
                :class="{ active: currentIndex === +index }"
            >
                <button @click="setIndex(+index)"></button>
            </li>
        </ul>
    </div>
</template>

<script>
import skeleton from '@/directives/skeleton';

export default {
    name: 'Carousel',
    directives: {
        skeleton
    },
    data() {
        return {
            slides: [],
            slidesCount: 0,
            currentIndex: 0,
            intervalId: null,
            observer: null
        };
    },
    props: {
        speed: {
            type: Number,
            default: 5000
        },
        autoplay: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        currentSlide() {
            return this.slides[this.currentIndex];
        }
    },
    methods: {
        handleMouseLeave() {
            if (!this.intervalId) {
                this.slidesCount && this.autoPlay();
            }
        },
        setIndex(index) {
            this.stop();
            this.currentIndex = index;
        },
        getNextIndex() {
            return (this.currentIndex + 1) % this.slides.length;
        },
        setSlide() {
            for (let i = 0; i < this.slidesCount; ++i) {
                this.slides[i].classList.remove('active');
            }
            this.currentSlide.classList.add('active');
            this.$emit('slideChange', this.currentIndex, this.getNextIndex()); //actual slide change occurs after transition duration
        },
        autoPlay() {
            if (!this.autoplay) {
                return;
            }
            this.setSlide();
            this.intervalId = setInterval(() => {
                this.currentIndex = this.getNextIndex();
            }, this.speed);
        },
        stop() {
            clearInterval(this.intervalId);
            this.intervalId = null;
        },
        addDotsPadding() {
            const dots = this.$refs.dots;
            if (dots) {
                const dotsWidth = dots.clientWidth;
                for (let i = 0; i < this.slidesCount; ++i) {
                    const teaserText = this.slides[i].querySelector('.teaser-text');
                    if (teaserText) {
                        teaserText.style.paddingRight = `${dotsWidth}px`;
                    }
                }
            }
        },
        addSlideClass() {
            for (let i = 0; i < this.slidesCount; ++i) {
                this.slides[i].classList.add('slide');
            }
        },
        init() {
            this.slides = this.$refs.track.children;
            this.slidesCount = this.slides.length;

            if (this.slidesCount <= 1) {
                return;
            }

            this.$refs.carousel.style.height = this.slides[0].clientHeight + 'px';

            //adding proper styling and transitions
            this.addSlideClass();

            this.autoplay && this.autoPlay();

            this.$nextTick(() => {
                this.addDotsPadding();
                this.$emit('init');
            });
        }
    },
    watch: {
        currentIndex(index, prevIndex) {
            this.slides[prevIndex] && this.slides[prevIndex].classList.add('expiring');
            this.setSlide();

            setTimeout(() => {
                this.slides[prevIndex] && this.slides[prevIndex].classList.remove('expiring');
            }, 500); //same timeout as transition duration
        },
        autoplay(autoplay) {
            autoplay ? this.autoPlay() : this.stop();
        }
    },
    mounted() {
        if (this.$getSafe(this.$refs, 'track.children.length')) {
            this.init();
        }

        this.observer = new MutationObserver(
            function (mutationsList, observer) {
                for (let mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        if (this.currentIndex >= this.slides.length) {
                            this.currentIndex = this.getNextIndex();
                        }
                        this.init();
                    }
                }
            }.bind(this)
        );
        this.observer.observe(this.$refs.track, {
            attributes: true,
            childList: true,
            subtree: false
        });

        this.$on('updatedTeaser', () => {});
    },
    beforeDestroy() {
        clearInterval(this.intervalId);
        this.observer.disconnect();
        this.$off('updatedTeaser');
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/bootstrap/breakpoints' as breakpoints;
@use '@/styles/theming' as *;

.carousel {
    position: relative;
    overflow: hidden;
    width: 100%;

    @include breakpoints.media-breakpoint-only(xl) {
        height: var(--teaser-height-xl);
        max-height: var(--teaser-height-xl);
    }

    @include breakpoints.media-breakpoint-only(lg) {
        height: var(--teaser-height-lg);
        max-height: var(--teaser-height-lg);
    }

    @media screen and (max-height: 900px) and (min-width: breakpoints.breakpoint-min(lg)) {
        height: var(--teaser-height-lg);
        max-height: var(--teaser-height-lg);
    }

    .carousel-track {
        position: relative;
        width: 100%;
        /*height: 100%;*/

        .slide {
            opacity: 0;
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: opacity ease-in-out 0.5s;

            &.active {
                opacity: 1;
                z-index: 2;
            }

            &.expiring {
                opacity: 1;
                z-index: 1;
            }

            ::v-deep .teaser-text {
                padding-right: 60px;
            }
        }
    }

    ul.slide-control {
        position: absolute;
        right: variables.$grid-gutter-width;
        bottom: variables.$grid-gutter-width;
        margin-bottom: 0;
        display: flex;
        list-style-type: none;
        z-index: 3;
        padding-left: variables.$grid-padding;

        > li {
            opacity: 0.5;
            margin: 0 5px;

            &.active {
                opacity: 1;
            }

            > button {
                width: 10px;
                height: 10px;
                background-color: hsl(0, 0%, 100%);
                display: block;
                border-radius: 100%;
                border: none;
                padding: 0;
                cursor: pointer;
                outline: none;
            }
        }
    }
}
</style>
