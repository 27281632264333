<template>
    <section class="content-text">
        <HTMLContent :content="content" :is-small="small" />
    </section>
</template>

<script>
import HTMLContent from '@/components/html/HTMLContent';

export default {
    name: 'PageContent',
    components: { HTMLContent },
    props: {
        content: {
            type: String,
            required: true,
            default: ''
        },
        small: Boolean
    }
};
</script>

<style scoped lang="scss">
.content-text {
    overflow: hidden;
    background-color: var(--body-bg);
}
</style>
