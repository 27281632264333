const addSkeleton = (el, binding) => {
    el.classList.add('skeleton');
    const css = getComputedStyle(el);
    if (!css.height || css.height === 'auto' || css.height === "0px") {
        el.style.height = '1em';
    }
    if (binding.arg) {
        el.style.width = binding.arg + 'px';
        el.classList.add('inline');
    }
}

export default {
    bind(el, binding) {
        if (binding.value) {
            return;
        }
        el.classList.add('skeleton');
    },
    inserted(el, binding) {
        if (binding.value) {
            return;
        }
        addSkeleton(el, binding);
    },
    update(el, binding) {
        if (binding.value) {
            el.classList.remove('skeleton', 'inline');
            el.style.height = "";
            el.style.width = "";
        }
        else {
            addSkeleton(el, binding);
        }
    }
}
