<template>
    <div id="home">
        <USPBanner v-if="uspBannerVisible" />

        <media-only desktop>
            <Carousel
                v-if="isTeaserAreaVisible && homeContent.teaserBlocks && homeContent.teaserBlocks.length"
                :autoplay="carouselAutoPlay"
                @slideChange="lazyLoadTeaser"
                class="carousel"
                ref="carousel"
            >
                <Teaser
                    v-for="(teaser, index) in homeContent.teaserBlocks"
                    :key="'teaser' + index"
                    :backgroundUrl="teaser.imageUrl"
                    :gift-box-banner-visible="giftBoxBannerVisible"
                    :alt="teaser.text || teaser.title"
                    :force-load="index === 0"
                >
                    <component :is="teaserComponent" :teaser="teaser"></component>
                </Teaser>
            </Carousel>
        </media-only>

        <div class="container main">
            <media-only desktop>
                <SearchBar v-if="isSearchVisible" />
            </media-only>

            <MultivoucherSteps v-if="isMultiVoucher" class="mb-3" />

            <AccessCodeSteps v-if="isAccessCodeRequired && isAccessCodeStepsVisible" class="mb-3" />

            <!-- Special 3 step guide for KIU -->
            <template v-if="$store.state.ident === 'kurzinurlaub'">
                <media-only mobile>
                    <KIUThreeStepGuideMobile />
                </media-only>

                <media-only desktop>
                    <KIUThreeStepGuide />
                </media-only>
            </template>

            <media-only desktop>
                <h3 v-if="isMapVisible" class="grid-title">
                    {{ $t('Index.map') }}
                </h3>
                <HotelMap v-if="isMapVisible" :markers="markers"></HotelMap>
            </media-only>

            <Grid
                v-for="(grid, index) in grids"
                :key="'grid' + index"
                :grid="grid"
                :first="index === 0"
                :last="index === grids.length - 1"
                allow-resizing
            />

            <PageData v-if="pageData" :content="pageData" :small="isSmallPageData" class="mt-3" />

            <HotelPartners v-if="showPartners" class="mt-3" />
        </div>

        <!-- PromoteNewsletter v-if="newsletterEnabled" / -->
    </div>
</template>

<script>
import HomeTeaser from '@/components/teasers/HomeTeaser';
import Teaser from '@/components/teasers/Teaser';
import Carousel from '@/components/Carousel';
import Grid from '@/components/grid/Grid';
import PageData from '@/components/PageContent';
import GridClass from '@/models/Grid';
import getHead from '@/mixins/getHead';
import extraScriptCode from '@/mixins/extraScriptCode';
import advertisable from '@/mixins/advertisable';
import fetchResource from '@/utils/fetchResource';
import MediaOnly from '@/components/MediaOnly';
import ImageFluid from '@/components/ImageFluid';

export default {
    name: 'Home',
    layout: ({ layout }) => layout,
    async fetch(context) {
        await fetchResource(context, context.store.dispatch('getHome'));
    },
    head() {
        const pageData = this.homeContent.pageData || {}; // pageData should be in place, but who knows...
        return this.getHead({
            pageTitle: pageData.metaTitle,
            noindex: pageData.noindex,
            keywords: pageData.keywords,
            metaDescription: pageData.metaDescription,
            canonicalUrl: pageData.canonicalUrl,
            hrefLangs: pageData.hrefLangs
        });
    },
    data() {
        return {
            carouselAutoPlay: true, //needed for BO control
            teaserToLoad: 0 //initial teaser or 0 for single teaser
        };
    },
    mixins: [getHead, extraScriptCode('homeContent'), advertisable('homeContent')],
    components: {
        ImageFluid,
        MediaOnly,
        //Sync components
        Carousel,
        Teaser,
        HomeTeaser,
        Grid,
        PageData,
        //Async lazy components
        SearchBar: () => import(/* webpackChunkName: "search-bar" */ '@/components/search/SearchBar'),
        MultivoucherSteps: () =>
            import(/* webpackChunkName: "multivoucher-steps" */ '@/components/guideSteps/MultivoucherSteps'),
        AccessCodeSteps: () =>
            import(/* webpackChunkName: "accesscode-steps" */ '@/components/guideSteps/AccessCodeSteps'),
        HotelMap: () => import(/* webpackChunkName: "hotel-map" */ '@/components/map/HotelMap'),
        HotelPartners: () => import(/* webpackChunkName: "hotel-partners" */ '@/components/banners/HotelPartners'),
        NettoHomeTeaser: () =>
            import(
                /* webpackChunkName: "netto-teaser" */ '@/themes/nettovorteilswelt/components/teasers/NettoHomeTeaser'
            ),
        KIUThreeStepGuide: () =>
            import(/* webpackChunkName: "kurzinurlaub" */ '@/themes/kurzinurlaub/components/KIUThreeStepGuide.vue'),
        KIUThreeStepGuideMobile: () =>
            import(
                /* webpackChunkName: "kurzinurlaub" */ '@/themes/kurzinurlaub/components/KIUThreeStepGuideMobile.vue'
            )
        // PromoteNewsletter: () =>
        //     import(/* webpackChunkName: "newsletter-banner" */ '@/components/modals/PromoteNewsletter')
    },
    computed: {
        homeContent() {
            return this.$store.state.home;
        },
        isTeaserAreaVisible() {
            return this.$store.state.ident !== 'netto-discount' && this.$store.state.ident !== 'edeka-suedwest';
        },
        isSearchVisible() {
            return this.$store.state.globalMeta.searchBarVisible;
        },
        isMultiVoucher() {
            return this.$store.state.globalMeta.multivoucherShop;
        },
        isAccessCodeRequired() {
            return this.$store.state.globalMeta.accessCodeRequired;
        },
        isSpecialDiscountShop() {
            return this.isTwoForOneShop || this.$store.state.ident === 'douglas';
        },
        isTwoForOneShop() {
            return this.$store.state.ident === '2fuer1';
        },
        isAccessCodeStepsVisible() {
            //return !this.isSpecialDiscountShop && this.$store.state.ident !== 'ticketsprinter';
            return this.$store.state.ident !== 'ticketsprinter';
        },
        isMapVisible() {
            return (this.isMultiVoucher || this.isAccessCodeRequired) && this.markers && this.markers.length;
        },
        newsletterEnabled() {
            return this.$store.state.globalMeta.newsletterFormEnabled;
        },
        showPartners() {
            return (
                !this.$store.state.ident ||
                this.$store.state.ident === '' ||
                this.$store.state.ident === 'www' ||
                this.$store.state.ident === 'null' ||
                this.$store.state.ident === 'sonnenklar'
            );
        },
        grids() {
            /*
                    On mobile devices half of the screen is blank upon load. Skeleton helps improve usability.
                    Therefore constructing 3 blank grids before actual get loaded
                 */
            return (
                this.homeContent.grids ||
                Array.from({ length: 3 }).map(() => {
                    return new GridClass(this.$t('Grid.productsLoading'), [{}, {}, {}]);
                })
            );
        },
        markers() {
            return this.homeContent.mapProducts || [];
        },
        pageData() {
            if (this.homeContent.pageData) return this.homeContent.pageData.content;
            return null;
        },
        isSmallPageData() {
            return !!(this.homeContent.grids && this.homeContent.grids.length);
        },
        teaserComponent() {
            return this.$store.state.theme === 'nettovorteilswelt' ? 'NettoHomeTeaser' : 'HomeTeaser';
        }
    },
    methods: {
        lazyLoadTeaser(index) {
            this.teaserToLoad = index;
        },
        sendGTMEvent() {
            this.$gtm && this.$gtm.pushPageView('homepage');
        }
    },
    beforeMount() {
        this.sendGTMEvent();
    }
};
</script>
